import { nanoid } from 'nanoid';
import cv from '../cv/cv.pdf';

// HEAD DATA
export const headData = {
  title: 'João Lima', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'João Lima Personal Website and Portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'João Augusto Lima',
  subtitle: 'I am a Software Engineer',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'foto_recort2.jpg',
  paragraphOne:
    'Hello, my name is João Augusto Lima, and I am a Software Engineer with a Master Degree in Computer Engineering.',
  paragraphTwo:
    'I love making stuff that people enjoy and can have fun with. I never give up when faced with a complex challenge.',
  paragraphThree: 'I mainly focus on C# and C++, but I also have experience in other languages.',
  resume: cv, // if no resume, the button will not show up
};

// Works DATA
export const worksData = [
  {
    id: nanoid(),
    img: 'enscape.png',
    title: 'Software Developer at Chaos',
    info:
      'Develop and maintain new features on Enscape Software',
    info2:
      '',
    url: '',
    urlCompany: 'https://enscape3d.com/',
    repo: '', // if no repo, the button will not show up
    tech: 'C#',
  },
  {
    id: nanoid(),
    img: 'Glartek.png',
    title: 'Unity Developer at Glartek',
    info:
      'Responsible for the structuring, development and management of a Mixed Reality application using Unity for HoloLens.',
    info2:
      '',
    url: '',
    urlCompany: 'https://glartek.com/',
    repo: '', // if no repo, the button will not show up
    tech: 'C#, Unity',
  },
  {
    id: nanoid(),
    img: 'deloitte_digital.jpg',
    title: 'Backend Developer at Deloitte Digital',
    info:
      'Build, maintain and test enterprise codebases and products. Responsible for helping and implementing new features to thousands of users.',
    info2:
      '',
    url: '',
    urlCompany: 'https://www2.deloitte.com/pt/pt/pages/about-deloitte/articles/deloitte-digital-solutions.html',
    repo: '', // if no repo, the button will not show up
    tech: 'C#, .NET',
  },
  {
    id: nanoid(),
    img: 'inesc_logo.png',
    title: 'Summer Internship at INESC TEC',
    info:
      'A CTM Summer Internship 2019, entitled "Multimedia Platform for AI Visual Attention Analysis".',
    info2:
      'This internship was carried out in the Multimedia and Communications Technologies Area (MCT) of the Centre for Telecommunications and Multimedia (CTM) at INESC TEC, Porto, Portugal.',
    url: '',
    urlCompany: 'https://www.inesctec.pt/',
    repo: '', // if no repo, the button will not show up
    tech: 'Python, JavaScript',
  },
];
// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'redrunner.png',
    title: 'Red Runner Adaptivity Version',
    info:
      'Master\'s thesis project: An adaptive version of Red Runner, where the game is automatically modified by a Machine Learning Agent using Reinforcement Learning to improve the user\'s experience.',
    info2: '',
    url: 'https://tripor.itch.io/red-runner-adaptivity-version',
    repo: 'https://github.com/tripor/RedRunner', // if no repo, the button will not show up
    file: 'https://hdl.handle.net/10216/135599',
    tech: 'Unity, C#, Python, PyTorch',
  },
  {
    id: nanoid(),
    img: 'overtime_borderless_black.png',
    title: 'OverTime',
    info:
      "OverTime is a game about...time. You'll solve time puzzles and dodge dimensional anomalies, but be fast - the clock is ticking.",
    info2:
      "You work at a company that investigates dimensional shifts. Everything goes wrong and it's up to you to find out what happened, fix the paradox and escape, with only the aid of the Primer Gun - a time-shifting device that allows you to go back in time and collaborate with alternate timeline versions of yourself.",
    url: 'https://laidbackstrat.itch.io/overtime',
    repo: 'https://github.com/Raidenkyu/FEUP-DJCO-OverTime', // if no repo, the button will not show up
    tech: 'Unity, C#',
  },
  {
    id: nanoid(),
    img: 'frozenwatcher.png',
    title: 'FrozenWatcher',
    info: 'FrozenWatcher is a deck tracking software for the game Legends of Runeterra.',
    info2:
      'The application tracks the player’s deck during a game, registering any cards drawn or played.',
    url: '',
    repo: '', // if no repo, the button will not show up
    tech: 'Electron, React, Node.js, TypeScript, MongoDB',
  },
  {
    id: nanoid(),
    img: 'lazercraft.png',
    title: 'Lazercraft',
    info:
      'Lazercraft is an AR FPS tower-defense game where the goal is to defend your base from incoming waves of enemies',
    info2: '',
    url: 'https://laidbackstrat.itch.io/lazercraft',
    repo: 'https://github.com/tripor/FEUP-RVAU/tree/master/SecondProject', // if no repo, the button will not show up
    tech: 'Unity, Vuforia, C#',
  },
];
// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'joao.augusto.lima.98@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'itch-io',
      url: 'https://tripor.itch.io/',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/joão-lima-b899161a8',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/tripor',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
